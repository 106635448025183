<template>
  <div class="form-control">
    <div class="collect-editor" :class="{ 'collect-editor--invalid': validateError }">
      <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
      <p class="form-control__error">{{ validateError }}</p>
    </div>
  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@/plugins/ckeditor/ckeditor.js";
import UploadAdapter from "@/plugins/ckeditor/upload-adapter";
export default {
  props: { value: { type: String }, validateError: { type: String } },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        extraPlugins: [
          function (editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = loader => {
              return new UploadAdapter(loader, "/api/admin/file-uploader");
            };
          },
        ],
        mediaEmbed: {
          previewsInData: true,
        },
        fontSize: {
          options: [10, 12, 14, 16, 18, "default", 22, 24],
        },
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "underline",
            "link",
            "blockQuote",
            "|",
            "alignment",
            "numberedList",
            "bulletedList",
            "|",
            "fontfamily",
            "fontsize",
            "fontColor",
            "-",
            "mediaEmbed",
            "uploadImage",
            "htmlEmbed",
            "|",
            "undo",
            "redo",
          ],
          shouldNotGroupWhenFull: true,
        },
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:inline",
            "imageStyle:alignLeft",
            "imageStyle:alignRight",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage",
          ],
        },
      },
    };
  },
  created() {
    this.editorData = this.value;
  },
  watch: {
    editorData(val) {
      this.$emit("input", val);
    },
  },
  // methods: {
  //   async uploader(editor) {
  //     const router = "/api/admin/file-uploader";
  //     editor.plugins.get("FileRepository").createUploadAdapter = loader => {
  //       return new UploadAdapter(loader, router);
  //     };
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.form-control {
  width: 100%;
  position: relative;
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
}
</style>
